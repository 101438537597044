import { Injectable } from '@angular/core';
import { Tasks, ITaskBankPlannedTabKPI, TaskInfoResp, ITasksMap, IPlannedPBMTasksApiResponse, IPlannedPBMTasksMap } from 'src/app/shared/models/tasks-planned';
import { NotesTypeField, RouteState, TBTabName, TaskState, TaskType } from 'src/app/shared/models/core';
import { TitleCasePipe } from '@angular/common';
import { DataRepositoryService } from 'src/app/shared/services/data-repository/data-repository.service';
import { IUPPTasksMap } from '../../models/task-unplannedpickup';
import { IUnloadedTasksMap } from '../../models/task-unloaded';
import { TaskSelectPipe } from '../../pipe/task-select/task-select-pipe.pipe';

@Injectable({
  providedIn: "root"
})
export class TaskInfoFormatterService {
  private crypto = window.crypto;
  constructor(
    private titleCasePipe: TitleCasePipe
    , private dataRepositoryService: DataRepositoryService
    , private taskSelectPipe: TaskSelectPipe
  ) { }

  /**
     * Method performs the sorting of group 
     * and merge the final sorted tasks list in the tbcollection 
     * @param data task bank's task collection
     * @returns sorted items order of the collection
     */
  sortCollection(data: any, sortByProperty: any) {
    return data.sort((a: any, b: any) => { return (a[sortByProperty] < b[sortByProperty]) ? -1 : (a[sortByProperty] > b[sortByProperty]) ? 1 : 0 })
  }

  kpiCalculate(routeTasks: any) {
    let kpi = {
      noOfDeliveredTasks: 0,
      noOfDeliverTaskType: 0,
      noOfLateArrivedTasks: 0,
      noOfMissedTasks: 0,
      noOfPBMRequestedTasks: 0,
      noOfPickupTaskType: 0,
      noOfPlannedTasks: 0,
      noOfTasks: 0,
      totalTasksVolume: 0,
      totalTasksWeight: 0
    } as ITaskBankPlannedTabKPI;
    kpi.noOfTasks = routeTasks?.length;
    // routeTasks?.forEach((task: RouteTask) => {
    routeTasks?.forEach((task: Tasks) => {
      if (task?.task_type.toLowerCase() === TaskType.Deliver && task?.task_state.toLowerCase() === TaskState.Completed) {
        kpi.noOfDeliveredTasks += 1;
      }
      if (task?.task_state.toLowerCase() === TaskState.Planned
        || task?.task_state.toLowerCase() === TaskState.Completed
        || task?.task_state.toLowerCase() === TaskState.Loaded
        || task?.task_state.toLowerCase() === TaskState.Requested) {
        kpi.noOfPlannedTasks += 1;
      }
      if (task?.task_type?.toLowerCase() === TaskType.Deliver) {
        kpi.noOfDeliverTaskType += 1;
      }
      if (task?.task_type?.toLowerCase() === TaskType.Pickup) {
        kpi.noOfPickupTaskType += 1;
      }
      if (task?.task_state?.toLowerCase() === TaskState.Requested) {
        kpi.noOfPBMRequestedTasks += 1;
      }
      if (task?.task_state?.toLowerCase() != TaskState.Completed) {
        kpi.noOfMissedTasks = this.getMissedTasksCount(task?.task_time_window?.end, kpi.noOfMissedTasks);
      }
      if (routeTasks?.route_state?.toLowerCase() === RouteState.Onroute && task?.task_state?.toLowerCase() != TaskState.Completed) {
        kpi.noOfLateArrivedTasks = this.getArrivedLateTasksCount(task?.eta, task?.eta, kpi.noOfLateArrivedTasks);
      }
    });
    return kpi;
  }

  /**
   * Method calculates and returns the total number of missed task
   * if the end time of the task is less than current local time (CET timezone)
   * @param endTime input end time value
   * @param noOfMissedTasks count of missed task in a route, initially set at 0
   * @returns the total missed tasks 
   */
  private getMissedTasksCount(endTime: any, noOfMissedTasks: any) {
    let endDate = new Date(endTime).toLocaleString('sv-SE', {
      timeZone: 'Europe/Stockholm',
    })
    let currentDate = new Date().toLocaleString('sv-SE', {
      timeZone: 'Europe/Stockholm',
    });

    if (endDate < currentDate) {
      noOfMissedTasks += 1;
    }
    return noOfMissedTasks;
  }

  /**
   * Method calculates and return the total of late arriving task
   * if their updated ETA value is greater than planned ETA
   * 
   * @param updatedEta input value of ETA of a task
   * @param eta planned ETA value of a task 
   * @param noOfLateArrivedTasks count of late arriving task, initially set at 0
   * @returns the total of late arriving tasks
   */
  private getArrivedLateTasksCount(updatedEta: any, eta: any, noOfLateArrivedTasks: any) {
    let updatedEtaDate = new Date(updatedEta).toLocaleString('sv-SE', {
      timeZone: 'Europe/Stockholm',
    })
    let etaDate = new Date(eta).toLocaleString('sv-SE', {
      timeZone: 'Europe/Stockholm',
    })

    if (updatedEtaDate > etaDate) {
      noOfLateArrivedTasks += 1;
    }
    return noOfLateArrivedTasks;
  }

  groupCollection(xs: any, key: any) {
    return xs.reduce(function (rv: any, x: any) {
      let v = key instanceof Function
        ? key(x)
        : x[key];
      let el = rv.find((r: any) => r && r.key === v);
      if (el) {
        el.values.push(x);
      } else {
        rv.push({ key: v, values: [x] });
      }
      return rv;
    }, []);
  }

  private mapPlannedTaskDelta(tasksApiResponse: TaskInfoResp): Map<string, ITasksMap> {
    let routeTasks: Tasks[] = tasksApiResponse?.tasks ?? [];
    let tasksMap: Map<string, ITasksMap> = new Map<string, ITasksMap>();
    //grouping is required, because tasks array is mixed of multiple routes with random order
    let groups = this.groupCollection(routeTasks, 'route_id');
    //each group represents one route and group values represents list of tasks within it
    groups.forEach((group: any) => {
      //get the task list within group (route)
      routeTasks = group.values ?? [];
      routeTasks.forEach(task => {
        task.route_state = this.dataRepositoryService.kpiDataMap.get(task.route_id)?.route_state ?? '';

        let taskBankRoutes: ITasksMap = {} as ITasksMap;
        taskBankRoutes.route_id = task.route_id; //key gives routeId
        taskBankRoutes.route_name = task.route_name;
        taskBankRoutes.business_location_id = task.bl_id;
        taskBankRoutes.route_time_window = ''; //should be assigned in routeUtil service, from routeInfoMap Object
        taskBankRoutes.route_state = task.route_state; //should be assigned in routeUtil service, from routeInfoMap Object
        taskBankRoutes.bl_name = task.bl_name;
        taskBankRoutes.taskStateTab = TBTabName.Planned;
        taskBankRoutes.stop_coordinate = task.stop_coordinate;
        taskBankRoutes.task_coordinate = task.task_coordinate;
        taskBankRoutes.id = task.id ?? '';
        taskBankRoutes.stop_id = task?.stop_id ?? '';
        taskBankRoutes.stop_sequence = task?.stop_sequence + 1;
        taskBankRoutes.task_id = task?.task_id ?? '';
        taskBankRoutes.address = task?.address ?? '';
        taskBankRoutes.address_id = task?.address_id ?? '';
        taskBankRoutes.task_state = task?.task_state?.toLowerCase() === TaskState.Unplanned ? this.titleCasePipe?.transform(TaskState.Requested) : task?.task_state ?? '';
        taskBankRoutes.task_sequence = task?.task_sequence + 1;
        taskBankRoutes.task_point_name = task?.task_point_name ?? '';
        taskBankRoutes.task_time_window = task?.task_time_window ?? { end: '', begin: '' };
        taskBankRoutes.task_type = task?.task_type ?? '';
        taskBankRoutes.pbm_order_id = task?.pbm_order_id ?? '';
        taskBankRoutes.item_count = task?.item_count;
        taskBankRoutes.external_keys = task?.external_keys;
        taskBankRoutes.goods_count = task?.external_keys?.goods?.length ?? 0;
        taskBankRoutes.delivery_point_area_name = task?.delivery_point_area_name ?? '';
        taskBankRoutes.initial_eta = task?.initial_eta ?? '';
        taskBankRoutes.eta = task?.eta ?? '';
        taskBankRoutes.ata = task?.task_state?.toLowerCase() === TaskState.Completed ? task?.ata : task?.ata ?? '';
        taskBankRoutes.source = task.source ? task.source : '';
        taskBankRoutes.isMovementAllowed = this.taskSelectPipe.transform(task, TBTabName.Planned)
        taskBankRoutes.isSelectedByMap = this.dataRepositoryService.highlightedTaskOnMap.get(task?.task_id) ? true : false

        tasksMap.set(task.task_id, taskBankRoutes)
      })
      //setting planned tab KPIs
      // taskBankRoutes.kpi = this.kpiCalculate(routeTasks);

    });
    return tasksMap;
  }

  /**
   * Method creates a random hash value of a color
   * @returns returns the generated hash code value
   */
  getRandomColor() {
    const array = new Uint8Array(3);
    crypto.getRandomValues(array)
    return '#' + Array.from(array).map(byte => byte.toString(16).padStart(2, '0')).join('');
  }

  private mapPlannedTasksObject(tasksApiResponse: TaskInfoResp, selectedRoute: any): Map<string, ITasksMap> {
    let tasksMapObject: Map<string, ITasksMap> = new Map<string, ITasksMap>();
    let tasksList: any = [];
    let routeId = selectedRoute.routeId;
    let routeTasks: Tasks[] = tasksApiResponse?.tasks ?? [];
    //grouping is required, because tasks array is mixed of multiple routes with random order
    let groups = this.groupCollection(routeTasks, 'route_id');
    if (groups.length) {
      routeTasks = groups.find((f: any) => f.key == routeId).values
      let routeColor = this.getRandomColor();
      routeTasks.forEach(task => {
        task.route_state = selectedRoute?.routeState;

        let taskBankRoutes: ITasksMap = {} as ITasksMap;
        taskBankRoutes.route_id = task.route_id;
        taskBankRoutes.route_name = task.route_name;
        taskBankRoutes.route_color = routeColor;
        taskBankRoutes.business_location_id = task.bl_id;
        taskBankRoutes.route_time_window = selectedRoute?.route_time_window;
        taskBankRoutes.route_state = selectedRoute?.routeState;
        taskBankRoutes.bl_name = task.bl_name;
        taskBankRoutes.taskStateTab = TBTabName.Planned;
        taskBankRoutes.stop_coordinate = task.stop_coordinate;
        taskBankRoutes.task_coordinate = task.task_coordinate;
        taskBankRoutes.id = task.id ?? '';
        taskBankRoutes.stop_id = task?.stop_id ?? '';
        taskBankRoutes.stop_sequence = task?.stop_sequence + 1;
        taskBankRoutes.task_id = task?.task_id ?? '';
        taskBankRoutes.address = task?.address ?? '';
        taskBankRoutes.address_id = task?.address_id ?? '';
        taskBankRoutes.task_state = task?.task_state?.toLowerCase() === TaskState.Unplanned ? this.titleCasePipe?.transform(TaskState.Requested) : task?.task_state ?? '';
        taskBankRoutes.task_sequence = task?.task_sequence + 1;
        taskBankRoutes.task_point_name = task?.task_point_name ?? '';
        taskBankRoutes.task_time_window = task?.task_time_window ?? { end: '', begin: '' };
        taskBankRoutes.task_type = task?.task_type ?? '';
        taskBankRoutes.pbm_order_id = task?.pbm_order_id ?? '';
        taskBankRoutes.item_count = task?.item_count;
        taskBankRoutes.external_keys = task?.external_keys;
        taskBankRoutes.goods_count = task?.external_keys?.goods?.length ?? 0;
        taskBankRoutes.delivery_point_area_name = task?.delivery_point_area_name ?? '';
        taskBankRoutes.initial_eta = task?.initial_eta ?? '';
        taskBankRoutes.eta = task?.eta ?? '';
        taskBankRoutes.ata = task?.task_state?.toLowerCase() === TaskState.Completed ? task?.ata : task?.ata ?? '';
        taskBankRoutes.source = task.source ? task.source : '';
        taskBankRoutes.isMovementAllowed = this.taskSelectPipe.transform(task, TBTabName.Planned)
        taskBankRoutes.isSelectedByMap = false
        tasksList.push(taskBankRoutes)
        this.setTaskMap(task.task_id, taskBankRoutes);
      });

      //sort by stop sequence 
      tasksList = tasksList.sort((a: any, b: any) => (a.stop_sequence < b.stop_sequence ? -1 : 1))
      tasksMapObject = new Map([...tasksMapObject.entries()].sort((a, b) => Number(a[1].stop_sequence) - Number(b[1].stop_sequence)));

      //setting planned tab KPIs
      // taskBankRoutes.kpi = this.kpiCalculate(routeTasks);
      return tasksList;
    } else {
      return tasksMapObject;
    }
  }



  setTaskMap(taskId: string, taskBankRoutes: any) {
    this.dataRepositoryService.plannedTbCollection.set(taskId, taskBankRoutes);
  }

  taskAdaptArray(tasksApiResponse: TaskInfoResp, selectedRoute: any): Map<string, ITasksMap> {
    return this.mapPlannedTasksObject(tasksApiResponse, selectedRoute);
  }

  taskDeltaAdaptArray(tasksApiResponse: TaskInfoResp): Map<string, ITasksMap> {
    return this.mapPlannedTaskDelta(tasksApiResponse);
  }

  uppTaskAdapter(uppTaskApiResponse: any): Map<string, IUPPTasksMap> {
    return this.mapUPPTaskObject(uppTaskApiResponse);
  }

  mapUPPTaskObject(uppTaskApiResponse: any): Map<string, IUPPTasksMap> {
    let uppTasksMapObject: Map<string, IUPPTasksMap> = new Map<string, IUPPTasksMap>();
    let uppTasksList: any = [];
    let routeTasks: Tasks[] = uppTaskApiResponse?.tasks ?? [];
    if (routeTasks.length) {
      routeTasks.forEach(task => {
        const notes: any = this.parsetaskNote(task?.task_note);

        let taskBankRoutes: IUPPTasksMap = {} as IUPPTasksMap;
        taskBankRoutes.driver_instruction = notes.driverNote;
        taskBankRoutes.manager_instruction = notes.managerNote;
        taskBankRoutes.business_location_id = task.bl_id;
        taskBankRoutes.bl_name = task.bl_name;
        taskBankRoutes.taskStateTab = TBTabName.Unplannedpickup;

        taskBankRoutes.id = this.genearateRandomIds(uppTasksMapObject);
        taskBankRoutes.task_id = task?.task_id ?? '';
        taskBankRoutes.address = task?.address ?? '';
        taskBankRoutes.address_id = task?.address_id ?? '';
        taskBankRoutes.task_state = task?.task_state ?? '';
        taskBankRoutes.task_point_name = task?.task_point_name ?? '';
        taskBankRoutes.task_time_window = task?.task_time_window ?? {
          end: '',
          begin: '',
        };
        taskBankRoutes.task_type = task?.task_type ?? '';
        taskBankRoutes.pbm_order_id = task?.pbm_order_id ?? '';
        taskBankRoutes.item_count = task?.item_count;
        taskBankRoutes.external_keys = task?.external_keys;
        taskBankRoutes.goods_count = task?.external_keys?.goods?.length ?? 0;
        taskBankRoutes.delivery_point_area_name =
          task?.delivery_point_area_name ?? '';
        taskBankRoutes.source = task.source ? task.source : '';
        taskBankRoutes.vehicle_type = task?.vehicle_type
          ? task?.vehicle_type
          : '';
        taskBankRoutes.isMovementAllowed = this.taskSelectPipe.transform(task, TBTabName.Unplannedpickup);
        uppTasksList.push(taskBankRoutes)
        this.setUppTaskMap(task.task_id, taskBankRoutes);
      });
      return uppTasksList;
    } else {
      return uppTasksMapObject;
    }
  }
  setUppTaskMap(taskId: string, taskBankRoutes: any) {
    this.dataRepositoryService.unplannedTbCollection.set(taskId, taskBankRoutes);
  }
  genearateRandomIds(taskCollection: Map<string, IUPPTasksMap>) {
    const data = [...taskCollection.values()]
    let id: any;
    do {
      id = this.crypto.getRandomValues(new Uint32Array(1))[0];
    } while (data.some((x) => x.id === id));
    return id;
  }
  unloadedTaskAdapter(unloadedApiResponse: any): Map<string, IUnloadedTasksMap> {
    return this.mapUnloadedItemObject(unloadedApiResponse);
  }
  mapUnloadedItemObject(unloadedApiResponse: any): Map<string, IUnloadedTasksMap> {
    let unloadedItemsMapObject: Map<string, IUnloadedTasksMap> = new Map<string, IUnloadedTasksMap>();
    let unloadedItemsList: any = [];
    let routeItems: IUnloadedTasksMap[] = unloadedApiResponse?.items ?? [];
    if (routeItems.length) {
      routeItems.forEach(item => {
        unloadedItemsList.push(item)
        this.setUnloadedItemMap(item.item_id, item);
      });
      return unloadedItemsList;
    } else {
      return unloadedItemsMapObject;
    }
  }
  setUnloadedItemMap(itemId: string, item: any) {
    this.dataRepositoryService.unloadedTbCollection.set(itemId, item);
  }
  plannedPBMTaskAdapter(pbmApiResponse: IPlannedPBMTasksApiResponse): Map<string, IPlannedPBMTasksMap> {
    return this.mapPlannedPBMTaskObject(pbmApiResponse);
  }

  mapPlannedPBMTaskObject(pbmApiResponse: IPlannedPBMTasksApiResponse): Map<string, IPlannedPBMTasksMap> {
    let pbmMapObject: Map<string, IPlannedPBMTasksMap> = new Map<string, IPlannedPBMTasksMap>();
    let pbmTasksList: any = [];
    let routeTasks: Tasks[] = pbmApiResponse?.tasks ?? [];
    if (routeTasks.length) {
      routeTasks.forEach(task => {
        const notes: any = this.parsetaskNote(task?.task_note);

        let taskBankRoutes: IPlannedPBMTasksMap = {} as IPlannedPBMTasksMap;
        taskBankRoutes.driver_instruction = notes.driverNote;
        taskBankRoutes.manager_instruction = notes.managerNote;
        taskBankRoutes.business_location_id = task.bl_id;
        taskBankRoutes.bl_name = task.bl_name;
        taskBankRoutes.route_id = task.route_id;
        taskBankRoutes.route_name = task.route_name;
        taskBankRoutes.taskStateTab = TBTabName.Unplannedpickup;

        taskBankRoutes.id = task.id ?? '';
        taskBankRoutes.task_id = task?.task_id ?? '';
        taskBankRoutes.address = task?.address ?? '';
        taskBankRoutes.address_id = task?.address_id ?? '';
        taskBankRoutes.task_state = task?.task_state ?? '';
        taskBankRoutes.task_point_name = task?.task_point_name ?? '';
        taskBankRoutes.task_time_window = task?.task_time_window ?? { end: '', begin: '' };
        taskBankRoutes.task_type = task?.task_type ?? '';
        taskBankRoutes.pbm_order_id = task?.pbm_order_id ?? '';
        taskBankRoutes.item_count = task?.item_count;
        taskBankRoutes.external_keys = task?.external_keys;
        taskBankRoutes.goods_count = task?.external_keys?.goods?.length ?? 0;
        taskBankRoutes.delivery_point_area_name = task?.delivery_point_area_name ?? '';
        taskBankRoutes.source = task.source ? task.source : '';
        taskBankRoutes.vehicle_type = task?.vehicle_type ? task?.vehicle_type : '';
        taskBankRoutes.stop_sequence = task?.stop_sequence
        pbmTasksList.push(taskBankRoutes)
        this.setPbmTaskMap(task.task_id, taskBankRoutes);
      });
      return pbmTasksList;
    }
    else {
      return pbmMapObject;
    }
  }
  setPbmTaskMap(taskId: string, taskBankRoutes: any) {
    this.dataRepositoryService.plannedPbmTbCollection.set(taskId, taskBankRoutes);
  }
  parsetaskNote(note: string) {
    let driverNote = '';
    let managerNote = '';
    try {
      let notes = note.split(/\n/);
      if (notes.length == 1) {
        driverNote = notes[0].includes(NotesTypeField.driver)
          ? notes[0].split(/:/)[1].trim()
          : '';
        managerNote = notes[0].includes(NotesTypeField.manager)
          ? notes[0].split(/:/)[1].trim()
          : '';
      } else if (notes.length) {
        notes.forEach((note: string) => {
          let noteField = note.split(/:/)[0].trim();
          switch (noteField) {
            case NotesTypeField.driver:
              driverNote = note.split(/:/)[1].trim();
              break;
            case NotesTypeField.manager:
              managerNote = note.split(/:/)[1].trim();
              break;
          }
        });
      }
      return { driverNote: driverNote, managerNote: managerNote };
    } catch (err) {
      return { driverNote: driverNote, managerNote: managerNote };
    }
  }
}