import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, forkJoin, fromEvent, interval, mergeMap, takeLast, takeWhile } from 'rxjs';
import { BusinessLocations, Coordinate } from '../../models/business-location-details';
import { environment } from 'src/environments/environment';
import { KpiResponse, RouteInfoResp } from '../../models/kpi-details';
import { ConnectionService } from '../connection/connection.service';
import { DataRepositoryService } from '../data-repository/data-repository.service';
import { ResponseDataFormatService } from '../format/response-data-format.service';
import { ConnectionStatus, LocalStorage, TBTabName } from '../../entity/enums';
import { AppConstants } from '../../entity/constants';
import { SelectionOption } from '../../entity/utility-interfaces';
import { DataFormatService } from '../format/data-format.service';
import { Navigation } from '../../entity/navigation';
import { DisplayMeasuringUnit } from '../../models/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DataAccessService implements OnDestroy{

  constructor(
    private actionbarResFormatService: ResponseDataFormatService
    , private connectionService: ConnectionService
    , private dataRepositoryService: DataRepositoryService
    , private dataFormatService: DataFormatService
    , private http: HttpClient
    , private router: Router
  ) { 
    if(!this.selectedBusinessLocation){
      let bl:any = localStorage.getItem(LocalStorage.BusinessLocation)
      this.selectedBusinessLocation = JSON.parse(bl);
      this.dataRepositoryService.selectedBusinessLocation = JSON.parse(bl);
      let coordinates:any = localStorage.getItem(LocalStorage.BLCoordinates)
      if(coordinates !== undefined && coordinates !== "undefined"){
        this.selectedBLCoords = JSON.parse(coordinates);
        this.dataRepositoryService.blCoordinates = this.selectedBLCoords
      }
    }

    this.onlineStatus = fromEvent(window, ConnectionStatus.Online);
    this.offlineStatus = fromEvent(window, ConnectionStatus.Offline);

    this.onlineStatus.subscribe(e => {
      this.connectionSubscription.next(ConnectionStatus.Online);
      this.getRouteInfoDeltaOnce();
      this.getRouteInfoDelta();
    });

    this.offlineStatus.subscribe(e => {
      this.connectionSubscription.next(ConnectionStatus.Offline);
      this.routeInfoDeltaSubscription.unsubscribe();
    });

  } 

  //Service Lifecycle hooks
  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    this.routeInfoDeltaSubscription.unsubscribe();
    this.businessLocationSubscription.unsubscribe();
  }

  //private members
  private isUnplannedSelected: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private filterApplied: Subject<any> = new Subject();
  private kpiData: Subject<boolean> = new Subject();
  private kpiDeltaUpdates: Subject<boolean> = new Subject();
  private isTBTabSwitched: Subject<boolean> = new Subject();
  private isFavBLChanged: Subject<boolean> = new Subject();
  private connectionSubscription:  Subject<string> = new Subject();

  //public variables
  
  private isPlannedSelected: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private isPlannedPBMselected: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private isUnloadedSelected: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private isTaskUpdated= new BehaviorSubject<{data:any[],isDeltaUpdate:boolean}>({data:[],isDeltaUpdate:false});
  isBLSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  currentSelectedRoute: BehaviorSubject<any> = new BehaviorSubject(null);
  
  routeSubscription: Subscription = Subscription.EMPTY;
  routeInfoDeltaSubscription: Subscription = Subscription.EMPTY;
  businessLocationSubscription: Subscription = Subscription.EMPTY;
  //selected options
  selectedBusinessLocation:any = ''; 
  selectedBlTeams:SelectionOption[] = [];
  selectedBlRoutes:SelectionOption[] = [];
  selectedBLCoords:Coordinate;

  filterSelection = {
    selectedBL: '',
    costCenters: [],
    routeNames: [],
    dateOffset: 0,
    routeState: 'all'
  };
  lastFilteredBLRouteList: string[] = [];

  // Main KPI 
  totalTasks: number = 0;
  plannedTasks: number = 0;
  completedTasks: number = 0;
  loadedItems: number = 0;
  deliveredItems: number = 0;
  keyMatrix: any;
  loading: boolean = false;
  loadedTasks: number = 0;
  completedItems: number = 0;
  plannedItems: number = 0;
  successPickups: number = 0;
  successDeliveries: number = 0;
  kpiInitialized = false;
  //Observable variables
  fitlerApplied$ = this.filterApplied.asObservable()
  isunplannedselected$ = this.isUnplannedSelected.asObservable();
  isPlannedSelected$ = this.isPlannedSelected.asObservable();
  isPlannedPBMselected$ = this.isPlannedPBMselected.asObservable();
  isUnloadedSelected$ = this.isUnloadedSelected.asObservable();
  isTaskUpdated$ = this.isTaskUpdated.asObservable();
  kpiData$ = this.kpiData.asObservable();
  kpiDeltaUpdates$ = this.kpiDeltaUpdates.asObservable();
  isTBTabSwitched$ = this.isTBTabSwitched.asObservable();
  isFavBLChanged$ = this.isFavBLChanged.asObservable();
  connectionSubscription$ = this.connectionSubscription.asObservable();


  onlineStatus: Observable<Event>;
  offlineStatus: Observable<Event>;



  onFilterApply(filterApplied: any) {
    this.filterApplied.next(filterApplied);
  }

  onKPIData(isKpiData: boolean) {
    this.kpiData.next(isKpiData);
  }

  onKPIDeltaUpdates(isKPIDelta: boolean) {
    this.kpiDeltaUpdates.next(isKPIDelta);
  }

  onTBTabSwitched(isTBTabSwitched: boolean) {
    this.isTBTabSwitched.next(isTBTabSwitched);
  }
  
  onFavBLChanged(isFavBLChanged: boolean) {
    this.isFavBLChanged.next(isFavBLChanged);
  }
  //Public Methods

  setFilterApplied(data: boolean, appliedFilterChanges: any) {
    if (data) {
      if (!appliedFilterChanges.isBlChanged) {
        this.setLastFitleredRoutes();
      }
      this.clearKPIdata();
      if (appliedFilterChanges.isBlChanged || appliedFilterChanges.isCalendarDateChanged) {
        this.dataRepositoryService.selectedRouteList = [];
        this.clearTBData();
        this.clearEtag('All');
      }
      this.getKPIData(appliedFilterChanges);
    }
  }


  plannedRevised(pt:any){
    this.isPlannedSelected.next(pt);
  }
  unPlannedRevised(pt:any){
    this.isUnplannedSelected.next(pt);
  }
  pbmRevised(pt:any){
    this.isPlannedPBMselected.next(pt);
  }
  unloadedRevised(pt:any){
    this.isUnloadedSelected.next(pt);
  }
  taskRevised(data:any[],isDeltaUpdate:boolean){
    this.isTaskUpdated.next({data, isDeltaUpdate});
  }
  /**
   * METHODS FOR ACCESSING BUSINESS LOCATION INFORMATION
   */

  /**
   * Methods gets the business location list from server
   * @returns Subject Observable - An Object of business location id and name  
   */
  getBLData(): Observable<SelectionOption[]>{
    let blSingleSelectObject = new Subject<SelectionOption[]>;
    let blList: SelectionOption[] = [];
    this.businessLocationSubscription = this.getBusinessLocations()
    .subscribe({
      next: (resp: BusinessLocations) => {
        this.dataFormatService.sortByProperty(resp.business_locations,'name')
        const businessLocations = resp.business_locations
        const addresses = resp.addresses??[]
        businessLocations.forEach((bl:any) => {
          this.dataRepositoryService.businessLocationListMap.set(bl?.business_location_id, bl);
          if(addresses){
            const address = addresses?.find(x => Number(x.address_id) === Number(bl.address_id));
            if(address){
              this.dataRepositoryService.businessLocationAddress.set(bl?.business_location_id, address);
            }
          }
          blList.push({
            id: bl.business_location_id, value: bl.name,
          });
        });
        blList.sort((a, b) => a.value.localeCompare(b.value))
      }
      ,error(err) {},
      complete() {
        blSingleSelectObject.next(blList);
        blSingleSelectObject.complete();
      }
    })

    return blSingleSelectObject.asObservable();
  }

  getBusinessLocations(): Observable<BusinessLocations> {
    return this.http.get<BusinessLocations>(environment.businessLocationurl);
  }

  /**
   * METHODS FOR ACCESSING TEAMS INFORMATION
   */

  getBLTeamsRouteList(selectedBL:string): Observable<any>{
    let isTeamsRoutesList = new Subject<any>;
    let formattedData:any[] = [];
    if (!!selectedBL) {
      const teamsData = this.getTeamsData(selectedBL);
      const routeData = this.getRouteListData(selectedBL);

      forkJoin([teamsData,routeData])
        .pipe(takeLast(1))
        .subscribe({
          next: (res: any) => {
            formattedData.push(this.setCostCenters(res[0]));
            formattedData.push(this.setRouteList(res[1]));
            this.setBLTeamRouteAssociation(res[0], res[1]);
          }
          , error: (err: any) => { }
          , complete() {
            isTeamsRoutesList.next(formattedData);
            isTeamsRoutesList.complete();
          }
        })
    }
    return isTeamsRoutesList.asObservable();
  }

  getTeamsData(selectedBL:string): Observable<any>{
    return this.connectionService.httpReq(
      AppConstants.GETREQUEST
      ,`${AppConstants.BUSINESSLOCATIONS}/${selectedBL}/${AppConstants.COSTCENTERS}?${AppConstants.DAYSOFFSET}=${this.dataRepositoryService.dateOffset}`
      , 'v4'
    );
  }

  setCostCenters(teams: any) {
    const costCenters = teams?.cost_centers && teams?.cost_centers.length > 0 ? teams.cost_centers : [];

    return costCenters;
  }

  /**
   * @param bl_id Business Location id
   * @returns to get the routes of selected Bl
   */
  getRouteListData(selectedBL:string) {
    const queryParams = {
      metadata : true,
      daysoffset: this.dataRepositoryService.dateOffset,
      select:'route_id,route_label,cost_center_id,route_state',
    }
    return this.connectionService.httpReq(
      AppConstants.GETREQUEST
      ,`${AppConstants.BUSINESSLOCATIONS}/${selectedBL}/${AppConstants.ROUTES}`
      , 'v4'
      , queryParams
    );
  }

  setRouteList(routesResponse:any) {
    let routes = routesResponse?.routes.length > 0 ? routesResponse.routes : [];
    routes = routes.filter((el:any, i:number, a:any) => i === a.indexOf(a.find((f:any) => f.route_label === el.route_label)));

    return routes;
  }
  
  
  
  /**
   * METHODS FOR ACCESSING AND MANAGING KPI DATA
   */
  setLastFitleredRoutes(){
    this.lastFilteredBLRouteList = [...this.dataRepositoryService.kpiDataMap.values()].map(kpiRoute=>kpiRoute.route_id)
  }

  checkRouteCountChange() {
    if(this.lastFilteredBLRouteList.length){
      let newRoutes: string[] = [...this.dataRepositoryService.kpiDataMap.values()].map(kpiRoute => kpiRoute.route_id)
      this.dataRepositoryService.removedRouteIds = this.lastFilteredBLRouteList.filter(lastRoutes => !newRoutes.includes(lastRoutes));
      // if (newRoutes.length < this.lastFilteredBLRouteList.length) {
      //   this.dataRepositoryService.removedRouteIds = this.lastFilteredBLRouteList.filter(lastRoutes => !newRoutes.includes(lastRoutes));
      // }
      // else if (newRoutes.length > this.lastFilteredBLRouteList.length) {
      //   this.dataRepositoryService.removedRouteIds = this.lastFilteredBLRouteList.filter(lastRoute => !newRoutes.includes(lastRoute));
      // }
      // else if (newRoutes.length === this.lastFilteredBLRouteList.length) {
      //   this.dataRepositoryService.removedRouteIds = this.lastFilteredBLRouteList.filter(lastRoute => !newRoutes.includes(lastRoute));
      // }
      //check if the removed route was also selected then remove those route from selected route list
      this.dataRepositoryService.removedRouteIds?.forEach(rmRoute=>{
        let index = this.dataRepositoryService.selectedRouteList.findIndex((selectedRoute:any)=>selectedRoute.route_id===rmRoute);
        if(index!=-1){
          if(this.dataRepositoryService.selectedRouteList.length==1){
            this.dataRepositoryService.selectedRouteList = []
          }
          else if(this.dataRepositoryService.selectedRouteList.length > 1){
            this.dataRepositoryService.selectedRouteList.splice(index,1);
          }
        }
      })
    }
  }

  clearKPIdata(){
    this.dataRepositoryService.kpiDataMap.clear();
    this.dataRepositoryService.removedRouteIds = [];
    this.filterSelection = {
      costCenters:[],
      routeNames:[],
      selectedBL:'',
      dateOffset: 0,
      routeState: 'all'
    }
  }

  /**
   * Method gets the KPI data from routeInfo API call and updates 
   * the repository.
   * @param isFilterApplied object contains boolean flags for the selection
   * changes occurred in filter
   */
  getKPIData(isFilterApplied?: any) {
    this.getStoredFilterValues();
    this.routeSubscription = this.getRouteInfo()
      .pipe(takeLast(1))
      .subscribe({
        next: (response: any) => this.handleResponse(response),
        error: (error) => { },
        complete: () => this.handleCompletion(isFilterApplied)
      });
  }
  /**
   * call the adapter service to format the response
    */
  handleResponse(response: any) {
    const routeinfoRes: RouteInfoResp = this.actionbarResFormatService.resModelFormatter(response);
    if (routeinfoRes.route_info) {
      this.dataRepositoryService.routeInfoEtag = routeinfoRes?.etag ?? 0;
      this.dataRepositoryService.routesList = routeinfoRes?.route_info;
    }
  }
  /**
    * Method FOR USER ACCOUNT INFORMATION
    */
  handleCompletion(isFilterApplied?: any) {
    if (this.isRoutesListNonEmpty()) {
      if (this.dataRepositoryService.kpiDataMap?.size) {
        this.handleKpiData(isFilterApplied);
      }
    } else if (this.isRoutesListEmpty()) {
      this.handleEmptyRoutesList(isFilterApplied);
    }
  }
  /**
    * Method for routelist is not empty
    */
  isRoutesListNonEmpty() {
    return this.dataRepositoryService.routesList?.length && !this.dataRepositoryService.routesList.every(x => x.routes.length === 0);
  }
  /**
    * Method for routelist is empty
    */
  isRoutesListEmpty() {
    return this.dataRepositoryService.routesList?.length && this.dataRepositoryService.routesList.every(x => x.routes.length === 0);
  }
  /**
    * Method for kpidata
    */
  handleKpiData(isFilterApplied?: any) {
    if (this.router.url === Navigation.MainKPI) {
      this.dataRepositoryService.routesList[0].routes.forEach((route: any) => {
        this.calculateKpiExtendedItems(route);
        this.dataRepositoryService.kpiDataMap.set(route.route_id, route);
      });
      this.initDataValues();
      this.updateKpiSummary();
    }

    this.refreshKpiDataMap();
    this.checkRouteCountChange(); //identify the routes list count change
    this.informKpiComponent(isFilterApplied);
    this.checkRouteInfoDeltaSubscription();
    this.clearFilterSetups();
  }
  /**
    * Calculate delivery not completed and pickup not completed
    */
  calculateKpiExtendedItems(route: any) {
    route.kpi_extended.items.not_completed = route?.kpi_extended?.items?.total - (route?.kpi_extended?.items?.delivered + route?.kpi_extended?.items?.failedattempt);
    route.kpi_extended.items.pickup_not_completed = route.kpi_extended?.items?.pickup - (route?.kpi_extended?.items?.pickup_completed + route?.kpi_extended?.items?.pickup_failed);
  }
  /**
    * Method to add new entries in kpidatamap
    */
  refreshKpiDataMap() {
    const newTaskMap = new Map(this.dataRepositoryService.kpiDataMap.entries());
    this.dataRepositoryService.kpiDataMap = newTaskMap;
  }

  informKpiComponent(isFilterApplied?: any) {
    if (isFilterApplied?.isBlChanged || isFilterApplied?.isCalendarDateChanged) {
      this.onFilterApply(true);
    } else {
      this.onKPIData(true);
    }
  }
  /**
    * Method to check route info subscription
    */
  checkRouteInfoDeltaSubscription() {
    if (this.dataRepositoryService.dateOffset === 0 && (this.routeInfoDeltaSubscription?.closed || !this.routeInfoDeltaSubscription)) {
      this.getRouteInfoDelta();
    }
  }
  /**
    * Method to clear filtered routelist
    */
  clearFilterSetups() {
    this.lastFilteredBLRouteList = [];
  }
  /**
    * Method to handle routelist
    */
  handleEmptyRoutesList(isFilterApplied?: any) {
    this.checkRouteCountChange();
    if (isFilterApplied?.isBlChanged) {
      this.onFilterApply(true);
    } else {
      this.onKPIData(true);
    }
    this.checkRouteInfoDeltaSubscription();
  }


    /**
   * Methos reset the KPI values
   */
  initDataValues() {
    this.plannedTasks = 0;
    this.completedTasks = 0;
    this.loadedTasks = 0;
    this.plannedItems = 0;
    this.loadedItems = 0;
    this.completedItems = 0;
    this.deliveredItems = 0;
    this.successDeliveries = 0;
    this.successPickups = 0;
  }

    /**
   * Methods calculates the summary KPIs from each route object
   */
    updateKpiSummary() {
      let itemsCount = 0;
      let pickupItemsCount = 0;
      let pickupItems = 0;
      [...this.dataRepositoryService.kpiDataMap.values()].forEach((route:any) => {
        this.deliveredItems += route?.kpi_extended?.items.delivered ?? 0;
        itemsCount += route?.kpi_extended?.items?.total ?? 0;
        //*** Tasks related Total */
        this.completedTasks += route?.kpi_extended?.tasks?.completed ?? 0;
        this.plannedTasks += route?.kpi_extended?.tasks?.planned_before_loading ?? 0;
        this.loadedTasks += route?.kpi_extended?.tasks?.loaded_before_onroute ?? 0;
        /**
         * Items related Total
         */
        this.completedItems += route?.kpi_extended?.items?.delivered ?? 0;
        this.plannedItems += route?.kpi?.items?.csp_sorted ?? 0; //instead of planned_before_loading we are using csp_sorted
        this.loadedItems += route?.kpi_extended?.items?.loaded_before_onroute  ?? 0;
        pickupItemsCount += route.kpi_extended?.items?.pickup ?? 0;
        pickupItems += route?.kpi_extended?.items?.pickup_completed?? 0;
      });

      if (itemsCount != 0) {
        this.successDeliveries =
        Math.round((this.deliveredItems / itemsCount) * 100 * 100) / 100;
      }

      if (pickupItemsCount != 0) {
        this.successPickups =
        Math.round((pickupItems / pickupItemsCount) * 100 * 100) / 100;
      }
      this.keyMatrix = this.formatKeyMatrixForMainScreen();
    }
  
    formatKeyMatrixForMainScreen() {
      return [
        {
          task: 'Tasks Completed',
          loadedTasks:this.loadedTasks,
          completedTasks: this.completedTasks,
        },
        {
          task: 'Items Delivered',
          plannedItems:this.plannedItems,
          loadedItems: this.loadedItems,
          completedItems:this.completedItems,
        },     
        {
          task: 'Success Deliveries',
          class: 'SuccessDeliveries',
          totalTime: this.successDeliveries + DisplayMeasuringUnit.Perecentage,
        },
        {
          task: 'Success Pickups',
          class: 'SuccessDeliveries',
          totalTime: this.successPickups + DisplayMeasuringUnit.Perecentage,
        }
      ];
    }

  getRouteInfoDelta(){
    this.routeInfoDeltaSubscription = interval(1 * 60 * 1000)
      .pipe(
        takeWhile(value => this.dataRepositoryService.dateOffset===0),
        mergeMap(() =>
        this.getRouteInfoDeltaResponse()
        )
      ).subscribe({
        next: (deltaRes:any) => {
          const routeinfoRes: RouteInfoResp = this.actionbarResFormatService.deltaAdaptArray(deltaRes);
          if (!routeinfoRes.hasOwnProperty('error')) {
            if( routeinfoRes.route_info){
              this.dataRepositoryService.routeInfoEtag = routeinfoRes?.etag??0;
              this.dataRepositoryService.routesList = routeinfoRes?.route_info??[];
            }
            if (routeinfoRes?.route_info?.length) {
              this.checkForRemovedRoutes(deltaRes);
              this.initDataValues();
              this.updateKpiSummary();
            }
            this.onKPIDeltaUpdates(true);
          }
        },
        error: (err) => {
          this.getRouteInfoDeltaResponse()
        },
        complete: () => { }
      })

  }

  getRouteInfoDeltaOnce(){
    this.getRouteInfoDeltaResponse()
      .pipe(takeLast(1))
      .subscribe({
        next: (deltaRes: any) => {
          const routeinfoRes: RouteInfoResp = this.actionbarResFormatService.deltaAdaptArray(deltaRes);
          if (!routeinfoRes.hasOwnProperty('error')) {
            if (routeinfoRes.route_info) {
              this.dataRepositoryService.routeInfoEtag = routeinfoRes?.etag ?? 0;
              this.dataRepositoryService.routesList = routeinfoRes?.route_info ?? [];
            }
            if (routeinfoRes?.route_info?.length) {
              this.checkForRemovedRoutes(deltaRes);
              this.initDataValues();
              this.updateKpiSummary();
              this.kpiDeltaUpdates.next(true);
            }
            this.onKPIDeltaUpdates(true);
          }
        },
        error: (err) => {
          this.getRouteInfoDeltaResponse()
        },
        complete: () => { }
      })
  }

  /**
 * this method checks for the matching removed routes from the delta response and removes it from MapRoutesObject to render on UI
 * @param deltaRes pass delta respose object
 */
  checkForRemovedRoutes(deltaRes: any) {
    const removed_routes = deltaRes?.removed_routes ?? [];
    const deltaRouteList = deltaRes.route_info[0]?.routes ?? [];
    const mappedRouteList = [...(this.dataRepositoryService.kpiDataMap).values()]
    if (deltaRouteList.length > 0 || removed_routes?.length) {
      const removedRoutes = mappedRouteList.filter(route => removed_routes.some((route_id: any)=> route_id === route.route_id));
      if(removedRoutes?.length){
        removedRoutes?.forEach(rmRoute=>{
          this.dataRepositoryService.kpiDataMap.delete(rmRoute.route_id);
          this.dataRepositoryService.removedRouteIds.push(rmRoute.route_id);
        })
        this.dataRepositoryService.removedRouteIds.forEach(rmRoute=>{
          let index = this.dataRepositoryService.selectedRouteList.findIndex((selectedRoute:any)=>selectedRoute.route_id===rmRoute);
          if(index!=-1){
            if(this.dataRepositoryService.selectedRouteList.length==1){
              this.dataRepositoryService.selectedRouteList = []
            }
            else if(this.dataRepositoryService.selectedRouteList.length > 1){
              this.dataRepositoryService.selectedRouteList.splice(index,1);
            }
          }
        })
      }
      if(this.router.url === Navigation.MainKPI) {
        this.dataRepositoryService.routesList[0].routes.forEach((route:any) => {
          /** Calculate delivery not completed */
          route.kpi_extended.items.not_completed = route?.kpi_extended?.items?.total - (route?.kpi_extended?.items?.delivered +  route?.kpi_extended?.items?.failedattempt);
          /** Calculate pickup not completed */
          route.kpi_extended.items.pickup_not_completed = route.kpi_extended?.items?.pickup - (route?.kpi_extended?.items?.pickup_completed +  route?.kpi_extended?.items?.pickup_failed);
          this.dataRepositoryService.kpiDataMap.set(route.route_id,route)
        });
      }
      const newTaskMap = new Map(this.dataRepositoryService.kpiDataMap.entries());
      this.dataRepositoryService.kpiDataMap = newTaskMap;
    }
  }

  /**
   * Methods reads the local storage values and update the object for filter operation
   */
  getStoredFilterValues() {
    let bl: any = localStorage.getItem(LocalStorage.BusinessLocation)
    let teams: any = localStorage.getItem(LocalStorage.BLSelectedTeams)
    let routes: any = localStorage.getItem(LocalStorage.BLSelectedRoutes);
    let routeState: any = localStorage.getItem(LocalStorage.RouteState);

    let teamsMapObj: any = [...(new Map(JSON.parse(teams))).keys()];
    let routesMapObj: any = JSON.parse(routes);
    
    this.filterSelection.selectedBL = JSON.parse(bl);
    this.filterSelection.costCenters = teamsMapObj;
    this.filterSelection.routeNames = routesMapObj;
    this.filterSelection.routeState = JSON.parse(routeState);
  }

  getRouteInfo(): Observable<KpiResponse> {
    let queryParams: any = this.getQueryParams();

    return this.connectionService.httpReq('get', `routeinfo`, 'dp', queryParams, false, false);
  }

  getQueryParams() {
    let queryParams: any = {};
    if (this.filterSelection.routeNames.toString() != '') {
      queryParams.routeNames = this.filterSelection.routeNames.toString();
    }
    if (this.filterSelection.costCenters.toString() != '') {
      queryParams.costCenters = this.filterSelection.costCenters.toString();
    }
    if(this.router.url === Navigation.MainKPI) {
      queryParams.extended = true;
    }
    queryParams.delta = false;
    queryParams.blid = this.filterSelection.selectedBL;
    queryParams.daysoffset = this.dataRepositoryService.dateOffset;
    if (this.filterSelection.routeState.toLowerCase() !== 'all') {
      queryParams.routeState = this.filterSelection.routeState;
    }
    return queryParams;
  }

  getRouteInfoDeltaResponse(): Observable<KpiResponse> {
    let queryParams:any = {};
    if(this.filterSelection.routeNames.toString() != ''){
      queryParams.routeNames = this.filterSelection.routeNames.toString()
    }
    if(this.router.url === Navigation.MainKPI) {
      queryParams.extended = true;
    }
    if(this.filterSelection.costCenters.toString() != ''){
      queryParams.costCenters = this.filterSelection.costCenters.toString()
    }
    queryParams.delta = true
    queryParams.blid = this.filterSelection.selectedBL
    queryParams.daysoffset = this.dataRepositoryService.dateOffset;
    if (this.filterSelection.routeState.toLowerCase() !== 'all') {
      queryParams.routeState = this.filterSelection.routeState;
    }
    let etag = this.dataRepositoryService.routeInfoEtag;

    return this.connectionService.httpReq('get', `routeinfo`, 'dp', queryParams, etag, false, false);
  }
  
  /**
   * Method sets the team and associated route 
   * and assign to Map collection in data repository
   */
  setBLTeamRouteAssociation(blAllTeams:any,blAllRoutes:any){
    blAllTeams?.cost_centers?.forEach((team:any)=>{
      let matchedRoutes = blAllRoutes?.routes?.filter((route:any)=>route?.cost_center_id===team.cost_center_id);
      this.dataRepositoryService.blTeamRouteAssociation.set(team.cost_center_id,matchedRoutes);
    })
  }


  //Route operations
  setCurrentSelectedRoute(data: any){
    if (data) {
      const payload = {
        isChecked: data?.isChecked,
        routeId: data?.routeId,
        routeState: data?.routeState
      }
      this.currentSelectedRoute.next(payload);
    }
  }

  //METHODS FOR ACCESSING AND MANAGING TASK BANK DATA
  clearTBData() {
    this.dataRepositoryService.unplannedTbCollection.clear();
    this.dataRepositoryService.plannedTbCollection.clear();
    this.dataRepositoryService.plannedPbmTbCollection.clear();
    this.dataRepositoryService.unloadedTbCollection.clear();
  }

  clearEtag(tabName: string){
    switch(tabName.toLowerCase()){
      case TBTabName.Planned : {
        this.dataRepositoryService.plannedTbEtag = 0;
        break;
      }
      case TBTabName.Unplannedpickup : {
        this.dataRepositoryService.unplannedTbEtag = 0;
        break;
      }
      case TBTabName.PlannedPBM : {
        this.dataRepositoryService.plannedPbmTbEtag = 0;
        break;
      }
      case TBTabName.Unloaded : {
        this.dataRepositoryService.unloadedTbEtag = 0;
        
        break;
      }
      default:{
        this.dataRepositoryService.plannedTbEtag = 0;
        this.dataRepositoryService.unplannedTbEtag = 0;
        this.dataRepositoryService.plannedPbmTbEtag = 0;
        this.dataRepositoryService.unloadedTbEtag = 0;
      }
    }
  }

  clearLocalStorage(){
    localStorage.clear();
  }

}
