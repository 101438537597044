import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private isSelectedFromMap: Subject<{isSelectedFromMap: boolean, taskId: string}> = new Subject();
  isSelectedFromMap$ = this.isSelectedFromMap.asObservable();

  private isSelectedFromPlannedTable: Subject<{isSelectedFromPlannedTable: boolean, taskId: string}> = new Subject();
  isSelectedFromTaskBank$ = this.isSelectedFromPlannedTable.asObservable();
  constructor() { }

  /**
   * 
   * @param data type {isSelectedFromMap: boolean, taskId: string}
   */
  onSelectCoordinates(data:{isSelectedFromMap:boolean, taskId:string}) {
    const {isSelectedFromMap, taskId} = data;
    this.isSelectedFromMap.next({
      isSelectedFromMap,
      taskId
    });
  }

  /**
   * 
   * @param data type {isSelectedFromPlannedTable: boolean, taskId: string}
   */
  onSelectTaskFromPlannedTable(data:{isSelectedFromPlannedTable:boolean, taskId:string}) {
    const {isSelectedFromPlannedTable, taskId} = data;
    this.isSelectedFromPlannedTable.next({
      isSelectedFromPlannedTable,
      taskId
    });
  }

}
