import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../loader/loader.service';
const httpOptions = {
  headers: new HttpHeaders({
    ContentType: 'application/json',
    Authorization: ''
  }),
  body: ''
};

@Injectable()
export class ConnectionService {
 

  constructor(
    private http: HttpClient,
    private loader: LoaderService
  ) { }

  getFullURL(directoryType : string,){
   let FullURL 
    switch (directoryType) {
      case 'dp':
        FullURL = environment.baseUrl + environment.pathDP
        break;

      case 'v1':
        FullURL = environment.airApiUri + environment.pathV1
        break;

      case 'v4':
        FullURL = environment.baseUrl + environment.pathV4
        break;

      case 'address':
        FullURL = environment.baseUrl + environment.pathAddress
        break;

      case 'rps':
        FullURL = environment.baseUrl + environment.pathRPS
        break;

      default:

        break;

    }
    return FullURL;
    
  }

  httpReq(ReqType: string, endUrl: string, directoryType: string, data?: any, etag?: any, isFile = false, isLoader = true, responseType: any = 'json'): Observable<any> {

      const FullURL:any = this.getFullURL(directoryType) + endUrl;

    if (isLoader) {
      this.loader.toggleLoader(1);
    }

    
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if(etag != 0 && etag !=undefined){
      headers = new HttpHeaders().append("If-None-Match",etag.toString());
    }
    switch (ReqType) {
      case 'get':{
        const encodedURL = this.encodeURL(FullURL, data);
        return this.http.get(encodedURL, { headers: headers, responseType: responseType }).pipe(
          tap(res => {
            this.handleHTTPSuccess(res);
          }),
          catchError(this.handleError()),
          finalize(() => {
            if (isLoader) {
              this.loader.toggleLoader(0);
            }
          })
        );
      }
      case 'post':{
        return this.http.post(FullURL, data, { headers: headers }).pipe(
          tap(res => { this.handleHTTPSuccess(res); }),
          catchError(this.handleError()),
          finalize(() => {
            this.loader.toggleLoader(0);
          })
        );
      }
      case 'put':{
        return this.http.put(FullURL, data, { headers: headers }).pipe(
          tap(res => this.handleHTTPSuccess(res)),
          catchError(this.handleError()),
          finalize(() => {
            this.loader.toggleLoader(0);
          })
        );
      }
      case 'delete':{
        httpOptions.body = data;
        return this.http.delete(FullURL, httpOptions).pipe(
          tap(res => this.handleHTTPSuccess(res)),
          catchError(this.handleError()),
          finalize(() => {
            this.loader.toggleLoader(0);
          })
        );
      }
      default:
        return of();
    }
  }

  handleError<T>() {
    return (error: HttpErrorResponse): Observable<T> => {
      let errorMessage = error.headers.get("message");
      let statusCode = error.status.valueOf();
      const errors = { status: statusCode, error: errorMessage };
      return throwError(() => errors);
    };
  }

  handleHTTPSuccess(result: any) {
    return result;
  }

  encodeURL(url: string, data: any) {
    let encodedUrl = url;
    if (data) {
      encodedUrl =
        encodedUrl +
        '?' +
        Object.keys(data).map((k) => {
          return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]);
        })
          .join('&');
    }
    return encodedUrl;
  }

  mockHttpReq(mockResponse: any) {
    return new Observable((obs) => {
      setTimeout(() => {
        const data = mockResponse;
        obs.next(data);
      }, 2000);
    });
  }
}
