import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-error-window',
  templateUrl: './error-window.component.html',
  styleUrls: ['./error-window.component.scss']
})
export class ErrorWindowComponent {
  constructor(public authService: MsalService) { }
  desktopView = true;

  logout() {
    this.authService.logout();
  }
}
